<template>
  <MemberLayout>
    <div class="page">
      <div class="nav">
        <span style="margin-right: auto">{{ t('storeManagement') }}</span>
        <router-link :to="{ name: 'StoreManagement.Personalization.Page' }" class="btn">
          <i class="pi pi-palette"></i>
          {{ t('colorSettings') }}
        </router-link>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 card p-4">
            <router-view/>
          </div>
          <div class="col-lg-4">
            <Simulation :showcases="[]"/>
          </div>
        </div>
      </div>
    </div>
  </MemberLayout>
</template>

<script>
import {reactive, ref} from 'vue'
import MemberLayout from './MemberLayout'
import Simulation from '../components/HomePageManagement/Simulation'
import useGlobal from '../composables/useGlobal'

export default {
  components: {Simulation, MemberLayout},
  setup() {
    const {t, restApi, successNotify, errorNotify} = useGlobal()

    let image = reactive({
      data: null,
      url: '',
      name: '',
      has_changed: false
    })

    let store = ref({})


    return {
      image,
      t,
      store
    }
  }
}
</script>

<style scoped>
.list-group {
  display: grid;
  gap: 1em;
}

.list-group a {
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--text-color);
  transition: color 0.2s;
  border-radius: 12px;
  padding: 0.75rem 1rem;
  transition: background-color 0.15s;
}
</style>
